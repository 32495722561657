export function joinAnd(
  values: string[],
  t: (key: string, values: string[]) => string
) {
  if (values.length === 0) return '';

  if (values.length === 1) return values[0];

  return t('connectors.and', [
    values.slice(0, -1).join(', '),
    values.slice(-1)[0]
  ]);
}

export function joinOr(
  values: string[],
  t: (key: string, values: string[]) => string
) {
  if (values.length === 0) return '';

  if (values.length === 1) return values[0];

  return t('connectors.or', [
    values.slice(0, -1).join(', '),
    values.slice(-1)[0]
  ]);
}

export function animateDetailsElements() {
  class Accordion {
    el: HTMLElement & { open: boolean };
    summary: HTMLElement;
    content: HTMLElement;
    animation: Animation | null;
    isClosing: boolean;
    isExpanding: boolean;

    constructor(el: HTMLElement) {
      this.el = el as HTMLElement & { open: boolean };
      this.summary = el.querySelector('summary');
      this.content = el.querySelector('.content');

      this.animation = null;
      this.isClosing = false;
      this.isExpanding = false;
      this.summary.addEventListener('click', (e: MouseEvent) =>
        this.onClick(e)
      );
    }

    onClick(e: MouseEvent) {
      e.preventDefault();
      this.el.style.overflow = 'hidden';
      if (this.isClosing || !this.el.open) {
        this.open();
      } else if (this.isExpanding || this.el.open) {
        this.shrink();
      }
    }

    shrink() {
      this.isClosing = true;

      const startHeight = `${this.el.offsetHeight}px`;
      const endHeight = `${this.summary.offsetHeight}px`;

      if (this.animation) {
        this.animation.cancel();
      }

      this.animation = this.el.animate(
        {
          height: [startHeight, endHeight]
        },
        {
          duration: 400,
          easing: 'ease-out'
        }
      );

      this.animation.onfinish = () => this.onAnimationFinish(false);
      this.animation.oncancel = () => (this.isClosing = false);
    }

    open() {
      this.el.style.height = `${this.el.offsetHeight}px`;
      this.el.open = true;
      window.requestAnimationFrame(() => this.expand());
    }

    expand() {
      this.isExpanding = true;
      const startHeight = `${this.el.offsetHeight}px`;
      const endHeight = `${
        this.summary.offsetHeight + this.content.offsetHeight
      }px`;

      if (this.animation) {
        this.animation.cancel();
      }

      this.animation = this.el.animate(
        {
          height: [startHeight, endHeight]
        },
        {
          duration: 400,
          easing: 'ease-out'
        }
      );
      this.animation.onfinish = () => this.onAnimationFinish(true);
      this.animation.oncancel = () => (this.isExpanding = false);
    }

    onAnimationFinish(open: boolean) {
      this.el.open = open;
      this.animation = null;
      this.isClosing = false;
      this.isExpanding = false;
      this.el.style.height = this.el.style.overflow = '';
    }
  }

  document.querySelectorAll('details').forEach((el: HTMLElement) => {
    // eslint-disable-next-line no-new
    new Accordion(el);
  });
}
